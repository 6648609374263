import React from "react";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { Modal, Button } from "flowbite-react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import LogoComponent from "./LogoComponent";

import heclogo from "../assets/img/heclogo.png";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export default function SignIn() {
  //for HEC User Account
  const [isHec, setHec] = useState(false);

  const [login, setLogin] = useState(true);
  const [register, setRegister] = useState(false);
  const [localLoan, setLocalLoan] = useState(false);
  const [applicationForm, setApplicationForm] = useState(true);
  const [createAccount, setCreateAccount] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);

  //avoid triggering useEffect when someone is not not placed abutton
  const [pressedRegNumber, setPressedRegNumber] = useState(false);
  //checking study area is selected
  //const [selectedArea, setSelectedArea] = useState(false);
  //check if loan category is selected
  const [selectedVillage, setSelectedVillage] = useState(false);

  const [registrationNumber, setRegistrationNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nationalId, setNationalId] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [guardianName, setGuardianName] = useState("");
  const [indexNumber, setIndexNumber] = useState("");
  const [email, setEmail] = useState("");
  const [marks, setMarks] = useState("");
  const [combination, setCombination] = useState("");
  const [applyingAcademic, setApplyingAcademic] = useState("");
  const [completionYear, setCompletionYear] = useState("");
  const [studyArea, setStudyArea] = useState("");
  const [loanCategory, setLoanCategory] = useState(
    "Tuition Fees and Living Allowance"
  );
  const [college, setCollege] = useState("");
  const [qualification, setQualification] = useState("");
  const [campus, setCampus] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [district, setDistrict] = useState("");
  const [sector, setsector] = useState("");
  const [cell, setCell] = useState("");
  const [village, setVillage] = useState("");

  //checking marks
  const [checkResultRegNumber, setCheckResultRegNumber] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [bursaryStatus, setBursaryStatus] = useState("");

  //This is to be used to calculate the total marks to be considered for selection.
  const [perforamanceScore, setPerformanceScore] = useState("");
  const [qualificationScore, setQualificationScore] = useState("");
  const [totalScore, setTotalScore] = useState("");

  const [sectors, setSectors] = useState([]);
  const [cells, setCells] = useState([]);
  const [villages, setVillages] = useState([]);
  const [studyAreas, setStudyAreas] = useState([]);

  const [combinations, setCombinations] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);

  //create account
  const [firstNameAccount, setFirstNameAccount] = useState("");
  const [lastNameAccount, setLastNameAccount] = useState("");
  const [genderAccount, setGenderAccount] = useState("");
  const [emailAccount, setEmailAccount] = useState("");
  const [passwordAccount, setPasswordAccount] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [phoneNumberAccount, setPhoneNumberAccount] = useState("");
  const [nationalIdAccount, setNationalIdAccount] = useState("");

  //login In
  const [emailLogIn, setEmailLogIn] = useState("");
  const [passwordLogIn, setPasswordLogIn] = useState("");
  const [openModalLogin, setOpenModalLogin] = useState(false);

  //forgot password
  const [emailForget, setEmailForget] = useState("");
  const [emailSendSucceed, setEmailSendSucceed] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [token, setToken] = useState("");
  const [loginAuthen, setLoginAuthen] = useState("");

  const nav = useNavigate();

  const authenticationData = {
    userName: emailLogIn,
    password: passwordLogIn,
  };

  const logout = () => {
    console.log("logout");
    sessionStorage.clear();
    nav("/");
    setOpenModalLogin(false);
  };

  const loginAuthenticationToken = async (emailForget, reset) => {
    console.log("This is email" + emailForget);
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
          "hec/users/resetPassword?userName=" +
          emailForget +
          "&tokenType=" +
          reset
      )
      .then((response) => {
        console.log("email sent");
        return true;
      })
      .catch((error) => {
        return false;
      });
  };

  const authentication = async () => {
    if (emailLogIn && passwordLogIn) {
      await axios
        .get(process.env.REACT_APP_BASE_URL + "hec/users/login", {
          params: authenticationData,
        })
        .then((response) => {
          if (response.status === 200) {
            loginAuthenticationToken(authenticationData.userName, "LOGIN");
            setOpenModalLogin(true);
            //save data in session storage
            sessionStorage.setItem("user", JSON.stringify(response.data));
            //nav("/dashboard");
          } else {
            toast.warning(
              "Invalid User Name or Password, please double check again",
              {
                position: "top-center",
                autoClose: 10000,
                theme: "colored",
              }
            );
          }
        })
        .catch((error) => {
          toast.info("Invalid User Name  or password", {
            position: "top-center",
            autoClose: 15000,
            theme: "colored",
          });
        });
    } else {
      toast.warning("Please, provide all neccessary information", {
        position: "top-center",
        autoClose: 15000,
        theme: "colored",
      });
    }
  };

  const applicationData = {
    firstName: firstName,
    lastName: lastName,
    idNumber: nationalId,
    regNumber: registrationNumber,
    email: email,
    phoneNumber: phoneNumber,
    academicYearId: applyingAcademic,
    studyAreaId: studyArea,
    loanCategory: loanCategory,
    marks: marks,
    combinationId: combination,
    completionYearId: completionYear,
    fatherName: fatherName,
    motherName: motherName,
    gardian: guardianName,
    gender: gender,
    indexNumber: indexNumber,
    performanceScore: perforamanceScore,
    qualificationScore: qualificationScore,
    totalScore: totalScore,
    sectorId: sector,
    cellId: cell,
    villageId: village,
  };

  const createAccountData = {
    firstName: firstNameAccount,
    lastName: lastNameAccount,
    userName: emailAccount,
    email: emailAccount,
    password: passwordAccount,
    gender: genderAccount,
    nationalId: nationalIdAccount,
    phoneNumber: phoneNumberAccount,
  };

  const clearTheInputCreate = () => {
    setFirstNameAccount("");
    setLastNameAccount("");
    setGenderAccount("");
    setPhoneNumberAccount("");
    setNationalIdAccount("");
    setEmailAccount("");
    setPasswordAccount("");
    setPasswordCheck("");
  };
  const clearTheInput = () => {
    setRegistrationNumber("");
    setFirstName("");
    setLastName("");
    setGender("");
    setMotherName("");
    setFatherName("");
    setGuardianName("");
    setPhoneNumber("");
    setNationalId("");
    setEmail("");
    setIndexNumber("");
    setMarks("");
    setStudyArea("");
    setApplyingAcademic("");
    setCampus("");
    setCollege("");
    setQualification("");
    setEducationLevel("");
    setCompletionYear("");
    setLoanCategory("");
    setCombination("");
    setDistrict("");
    setsector("");
    setCell("");
    setVillage("");
  };

  const clearTheInPutGetBursary = () => {
    setFirstName("");
    setLastName("");
    setGender("");
    setMotherName("");
    setFatherName("");
    setGuardianName("");
    setPhoneNumber("");
    setNationalId("");
    setEmail("");
    setIndexNumber("");
    setMarks("");
    setStudyArea("");
    setApplyingAcademic("");
    setCampus("");
    setCollege("");
    setQualification("");
    setEducationLevel("");
    setCompletionYear("");
    setLoanCategory("");
    setCombination("");
    setDistrict("");
    setsector("");
    setCell("");
    setVillage("");
  };

  const applyForLocalLoan = async () => {
    if (
      registrationNumber &&
      firstName &&
      lastName &&
      nationalId &&
      fatherName &&
      motherName &&
      indexNumber &&
      qualification &&
      college &&
      selectedVillage
    ) {
      await axios
        .post(
          process.env.REACT_APP_BASE_URL + "hec/localLoan/saveLocalLoan",
          applicationData
        )
        .then((response) => {
          if (response.data === "Loan request not yet opened") {
            toast.warning(
              "Loan request process is not yet opened. Please, be patient.",
              {
                position: "top-center",
                autoClose: 15000,
                theme: "colored",
              }
            );
            clearTheInput();
          } else {
            toast.success("The Local loan request is succcessfully received.", {
              position: "top-center",
              autoClose: 15000,
              theme: "colored",
            });
            clearTheInput();
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
            toast.error("Your local loan is already received", {
              position: "top-center",
              autoClose: 15000,
              theme: "colored",
            });
            clearTheInput();
          } else {
            toast.info("Please, Try again", {
              position: "top-center",
              autoClose: 15000,
              theme: "colored",
            });
            clearTheInput();
          }
        });
    } else {
      toast.warning("Please, provide all neccessary information", {
        position: "top-center",
        autoClose: 15000,
        theme: "colored",
      });
    }
  };

  const getBursaryApplication = async () => {
    if (registrationNumber) {
      await axios
        .get(
          process.env.REACT_APP_BASE_URL +
            "hec/busaryApplication/getByRegistrationNumber",
          {
            params: { registrationNumber: registrationNumber },
          }
        )
        .then((response) => {
          //This is the condition
          if (response.data.state === 1) {
            setFirstName(response.data.firstName);
            setLastName(response.data.lastName);
            setGender(response.data.gender);
            setPhoneNumber(response.data.phoneNumber);
            setNationalId(response.data.nationalId);
            setEmail(response.data.email);
            setIndexNumber(response.data.indexNumber);
            setMarks(response.data.s6Aggregate);
            setCollege(response.data["college"].collegeName);
            setQualification(response.data["qualificationId"].qualifactionCode);
            setCampus(response.data.campusName);
            setEducationLevel(response.data.educationLevel);
            setCombination(response.data["combination"].combinationCode);
            setStudyArea(
              response.data["qualificationId"]["studyAreaId"].studyAreaName
            );
            setApplyingAcademic(response.data["academicYear"].academicYearName);
            setCompletionYear(response.data.completionYearCode);

            setPerformanceScore(
              process.env.REACT_APP_WEIGHT_ALLOCATED -
                (process.env.REACT_APP_MAX_PERFORMANCE -
                  response.data.s6Aggregate)
            );
            setQualificationScore(
              response.data["qualificationId"]["studyAreaId"].studyAreaWeight
            );
            setTotalScore(
              process.env.REACT_APP_WEIGHT_ALLOCATED -
                (process.env.REACT_APP_MAX_PERFORMANCE -
                  response.data.s6Aggregate) +
                response.data["qualificationId"]["studyAreaId"].studyAreaWeight
            );
          } else {
            toast.warning("You are not allowed to apply for this application", {
              position: "top-center",
              autoClose: 15000,
              theme: "colored",
            });
            clearTheInPutGetBursary();
            return null;
          }
        })
        .catch((error) => {
          clearTheInPutGetBursary();
          toast.warning(
            "That admission number is not found. Double check your number and try again, Eligible candidates are nationals who completed S6 in 2023 and admitted at UR/RP)",
            {
              position: "top-center",
              autoClose: 15000,
              theme: "colored",
            }
          );
          return null;
        });
    }
  };

  const clearResultFild = () => {
    setFirstName("");
    setLastName("");
    setNationalId("");
    setIndexNumber("");
    setRegistrationNumber("");
    setStudyArea("");
    setBursaryStatus("");
    setCheckResultRegNumber("");
  };

  const getResultByRegNumber = async () => {
    if (checkResultRegNumber) {
      await axios
        .get(
          process.env.REACT_APP_BASE_URL +
            `hec/localLoan/getLocalLoanByRegNumber?regNumber=${checkResultRegNumber}`
        )
        .then((response) => {
          setFirstName(response.data.firstName);
          setLastName(response.data.lastName);
          setNationalId(response.data.idNumber);
          setIndexNumber(response.data.indexNumber);
          setRegistrationNumber(response.data.regNumber);
          setStudyArea(response.data.studyAreaId);
          setBursaryStatus(response.data.status);
          setOpenModal(true);
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status === 404) {
            toast.warning(
              "The selected Students for bursary are not yet published. Please be patient",
              {
                position: "top-center",
                autoClose: 15000,
                theme: "colored",
              }
            );
            return null;
          } else {
            toast.info(
              "That registration number is not found. Double check your number and try again.",
              {
                position: "top-center",
                autoClose: 15000,
                theme: "colored",
              }
            );
            return null;
          }
        });
    }
  };

  const fetchDistricts = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "hec/location/getDistricts"
      );
      const distrctsList = await response.data;
      setDistricts(distrctsList);
      return distrctsList;
    } catch {
      return null;
    }
  };

  const fetchStudyAreas = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "hec/studyArea/getStudyAreas"
      );
      const studyAreasList = await response.data;
      setStudyAreas(studyAreasList);
      return studyAreasList;
    } catch {
      return null;
    }
  };

  const fetchLocations = async (parentCode, typeLocation) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "hec/location/getLocaitonsByParent",
        {
          params: { parentCode: parentCode },
        }
      );
      const locationList = await response.data;
      if (typeLocation === "DISTRICT") {
        setSectors(locationList);
      } else if (typeLocation === "SECTOR") {
        setCells(locationList);
      } else if (typeLocation === "CELL") {
        setVillages(locationList);
      }
    } catch {
      return null;
    }
  };

  const fetchCombination = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "hec/combination/getCombinations"
      );
      const combinationList = await response.data;
      setCombinations(combinationList);
      return combinationList;
    } catch {
      return null;
    }
  };

  const fetchAcademicYear = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "hec/academic/getAcademicYears"
      );
      const academicYearList = await response.data;
      setAcademicYears(academicYearList);
      return academicYearList;
    } catch {
      return null;
    }
  };

  const createAccountAuthentication = async () => {
    if (
      firstNameAccount &&
      lastNameAccount &&
      nationalIdAccount &&
      emailAccount &&
      passwordAccount &&
      phoneNumberAccount
    ) {
      await axios
        .post(
          process.env.REACT_APP_BASE_URL + "hec/users/saveUser",
          createAccountData
        )
        .then((response) => {
          toast.success("The account is created successfully.", {
            position: "top-center",
            autoClose: 15000,
            theme: "colored",
          });
          clearTheInputCreate();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            toast.info("You User Name is already taken, please change it", {
              position: "top-center",
              autoClose: 15000,
              theme: "colored",
            });
          } else if (error.response.status === 302) {
            toast.info("Your account is already exists", {
              position: "top-center",
              autoClose: 15000,
              theme: "colored",
            });
          }
        });
    } else {
      toast.warning("Please, provide all neccessary information", {
        position: "top-center",
        autoClose: 15000,
        theme: "colored",
      });
    }
  };

  const validateToken = async () => {
    if (loginAuthen) {
      await axios
        .get(
          process.env.REACT_APP_BASE_URL +
            "hec/users/validetetoken?token=" +
            loginAuthen
        )
        .then((response) => {
          setOpenModal(false);
          //save data in session storage
          nav("/dashboard");
        })
        .catch((error) => {
          setEmailForget("");
          if (error.response.status === 409 || error.response.status === 404) {
            toast.info("Invalid token", {
              position: "top-center",
              autoClose: 15000,
              theme: "colored",
            });
            return null;
          } else {
            toast.info("An error occurred while sending email", {
              position: "top-center",
              autoClose: 15000,
              theme: "colored",
            });
            return null;
          }
        });
    } else {
      toast.warning("Please, provide all neccessary information", {
        position: "top-center",
        autoClose: 15000,
        theme: "colored",
      });
    }
  };

  //resetting password
  const sendEmail = async () => {
    if (emailForget) {
      await axios
        .get(
          process.env.REACT_APP_BASE_URL +
            "hec/users/resetPassword?userName=" +
            emailForget +
            "&tokenType=RESET"
        )
        .then((response) => {
          setEmailForget("");
          toast.success(
            "Email with reset Password has been send successfully, please check your email",
            {
              position: "top-center",
              autoClose: 15000,
              theme: "colored",
            }
          );
          setEmailSendSucceed(true);
          return null;
        })
        .catch((error) => {
          setEmailForget("");
          if (error.response.status === 409 || error.response.status === 404) {
            toast.info("That email is not found", {
              position: "top-center",
              autoClose: 15000,
              theme: "colored",
            });
            return null;
          } else {
            toast.info("An error occurred while sending email", {
              position: "top-center",
              autoClose: 15000,
              theme: "colored",
            });
            return null;
          }
        });
    } else {
      toast.warning("Please, provide all neccessary information", {
        position: "top-center",
        autoClose: 15000,
        theme: "colored",
      });
    }
  };

  const resetNewPassword = async () => {
    if (emailForget && newPassword) {
      await axios
        .put(
          process.env.REACT_APP_BASE_URL +
            `hec/users/resetPassword?userName=${emailForget}&password=${newPassword}&token=${token}`
        )
        .then((response) => {
          toast.success("Password is reset successfully, go back to login", {
            position: "top-center",
            autoClose: 15000,
            theme: "colored",
          });
        })
        .catch((error) => {
          toast.info("Invalid user name or token", {
            position: "top-center",
            autoClose: 15000,
            theme: "colored",
          });
        });
    } else {
      toast.warning("Please, provide all neccessary information", {
        position: "top-center",
        autoClose: 15000,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (pressedRegNumber) {
        getBursaryApplication();
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [registrationNumber]);

  useQuery({
    queryKey: ["districts"],
    queryFn: () => fetchDistricts(),
    retry: 6,
  });

  useQuery({
    queryKey: ["areasKey"],
    queryFn: () => fetchStudyAreas(),
    retry: 6,
  });

  useQuery({
    queryKey: ["combinationsKey"],
    queryFn: () => fetchCombination(),
    retry: 6,
  });

  useQuery({
    queryKey: ["academicYearKey"],
    queryFn: () => fetchAcademicYear(),
    retry: 6,
  });

  if (!localLoan) {
    return (
      <div className="flex flex-row">
        <div className="mb-20 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
          {login && !register ? (
            <div className="mt-[10vh] xl:ml-[20vh] sm:ml-[1vh] md:mr-[20vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
              {isHec ? (
                <>
                  <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-black">
                    Sign In
                  </h4>
                  {/* User Email */}
                  <div className="mb-6">
                    <label
                      htmlFor="userEmail"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                    >
                      Email*
                    </label>
                    <input
                      type="email"
                      id="userEmail"
                      onChange={(e) => setEmailLogIn(e.target.value)}
                      value={emailLogIn}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-100 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="mail@info.com"
                      required
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="userPassword"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                    >
                      Password*
                    </label>
                    <input
                      type="password"
                      id="userPassword"
                      onChange={(e) => setPasswordLogIn(e.target.value)}
                      value={passwordLogIn}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-100 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      required
                    />
                  </div>
                  {/* Checkbox */}
                  <div className="mb-4 flex items-center justify-between px-2">
                    <button
                      onClick={() => {
                        setLocalLoan(true);
                        setCreateAccount(true);
                        setForgetPassword(true);
                      }}
                      className="ml-1 text-sm font-medium text-cyan-800 hover:text-cyan-800 dark:text-cyan-800"
                    >
                      Forgot Password?
                    </button>
                  </div>
                  <button
                    className="linear mt-2 w-full rounded-xl bg-cyan-800	 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    onClick={authentication}
                  >
                    Sign In
                  </button>
                  <Modal
                    show={openModalLogin}
                    size="md"
                    onClose={() => setOpenModalLogin(false)}
                    popup
                  >
                    <Modal.Header />
                    <Modal.Body>
                      <div className="text-center">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                          Check your email and send the token you got from
                          there.
                        </h3>
                        <div className="mb-2 block">
                          <input
                            type="text"
                            id="userPassword"
                            onChange={(e) => setLoginAuthen(e.target.value)}
                            value={loginAuthen}
                            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-100 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                            required
                          />
                        </div>

                        <div className="flex justify-center gap-4">
                          <Button color="success" onClick={validateToken}>
                            Send
                          </Button>
                          <Button color="gray" onClick={logout}>
                            No, cancel
                          </Button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </>
              ) : (
                <>
                  <h1 className="lg:text-lg md:text-sm font-extrabold">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-slate-500 to-red-500">
                      HEC Staff Only Can Sign In
                    </span>
                  </h1>
                  <button
                    className="linear mt-2 w-full rounded-xl bg-cyan-800	 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    onClick={() => setHec(true)}
                  >
                    LOGIN
                  </button>
                </>
              )}
              {isHec ? (
                <div className="mt-4">
                  <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                    Not registered yet?
                  </span>
                  <button
                    onClick={() => {
                      setLogin(false);
                      setRegister(true);
                      setLocalLoan(false);
                    }}
                    className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 text-cyan-800"
                  >
                    Create an account
                  </button>
                </div>
              ) : (
                <></>
              )}

              <div className="mb-6 flex items-center  gap-3">
                <div className="h-px w-full bg-gray-200 dark:bg-navy-700">
                  <button
                    onClick={() => {
                      setLocalLoan(true);
                      setApplicationForm(true);
                      setCreateAccount(true);
                      setForgetPassword(false);
                      setPressedRegNumber(true);
                    }}
                    className="ml-1 text-base font-medium text-cyan-800 hover:text-cyan-800 dark:text-cyan-800"
                  >
                    Apply Local Loan(Undergraduate)
                  </button>
                </div>
                <p className="text-base text-gray-600 dark:text-white"> -</p>
                <div className="h-px w-full bg-gray-200 dark:bg-navy-700">
                  <button
                    onClick={() => {
                      setLocalLoan(true);
                      setApplicationForm(false);
                      setCreateAccount(true);
                      setForgetPassword(false);
                    }}
                    className="ml-1 text-base font-medium text-cyan-800 hover:text-cyan-800 dark:text-cyan-800"
                  >
                    Requested Loan Results
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-[10vh] xl:ml-[20vh] sm:ml-[1vh] md:mr-[20vh]  w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[600px]">
              <h4 className="text-4xl font-bold text-navy-700 dark:text-black">
                Sign Up
              </h4>

              <div className="mb-16 flex items-center  gap-3">
                {/* First Name */}
                <div className="h-px w-full">
                  <div className="mb-6">
                    <label
                      htmlFor="createfName"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                    >
                      First Name*
                    </label>
                    <input
                      type="text"
                      id="createfName"
                      onChange={(e) => setFirstNameAccount(e.target.value)}
                      value={firstNameAccount}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600
                       dark:bg-slate-100 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="First name"
                      required
                    />
                  </div>
                </div>
                <p className="text-base text-gray-600 dark:text-white"> -</p>
                <div className="h-px w-full dark:bg-navy-700">
                  {/* Last Name */}

                  <div className="mb-6">
                    <label
                      htmlFor="createlName"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Last Name*
                    </label>
                    <input
                      type="text"
                      id="createlName"
                      onChange={(e) => setLastNameAccount(e.target.value)}
                      value={lastNameAccount}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm
                       text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600
                        dark:bg-slate-100 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="FLast Name"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="mb-16 flex items-center  gap-3">
                <div className="h-px w-full dark:bg-navy-700">
                  {/* Gender */}
                  <label
                    htmlFor="createGender"
                    className="mb-2 block text-sm font-medium text-black dark:bg-navy-700"
                  >
                    Gender*
                  </label>
                  <select
                    id="createGender"
                    onChange={(e) => setGenderAccount(e.target.value)}
                    value={genderAccount}
                    className="block w-full rounded-lg border border-gray-300
                     bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-100 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option defaultValue>Select Gender</option>
                    <option value="MALE">MALE</option>
                    <option value="FAMALE">FEMALE</option>
                  </select>
                </div>
                <p className="text-base text-gray-600 dark:text-white"> -</p>
                {/* Email */}
                <div className="h-px w-full  dark:bg-navy-700">
                  <div className="mb-6">
                    <label
                      htmlFor="createEmail"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                    >
                      Email*
                    </label>
                    <input
                      type="email"
                      id="createEmail"
                      onChange={(e) => setEmailAccount(e.target.value)}
                      value={emailAccount}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm
                       text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-100 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="email"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="mb-16 flex items-center  gap-3">
                <div className="h-px w-full dark:bg-navy-700">
                  {/* Password */}
                  <div className="mb-6">
                    <label
                      htmlFor="createPassword"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                    >
                      Password*
                    </label>
                    <input
                      type="password"
                      id="createPassword"
                      onChange={(e) => setPasswordAccount(e.target.value)}
                      value={passwordAccount}
                      placeholder="Min. 8 characters"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm
                       text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-100 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      required
                    />
                  </div>
                </div>
                <p className="text-base text-gray-600 dark:text-white"> -</p>
                <div className="h-px w-full dark:bg-navy-700">
                  {/* Password */}
                  <div className="mb-6">
                    <label
                      htmlFor="confirmPassword"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                    >
                      Confirm Password*
                    </label>
                    <input
                      type="password"
                      id="confirmPassword"
                      onChange={(e) => setPasswordCheck(e.target.value)}
                      value={passwordCheck}
                      placeholder="Min. 8 characters"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500
                       focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-100 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="mb-16 flex items-center  gap-3">
                {/* Phone */}
                <div className="h-px w-full  dark:bg-navy-700">
                  <div className="mb-6">
                    <label
                      htmlFor="createPhoneNumber"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                    >
                      Phone Number*
                    </label>
                    <input
                      type="text"
                      id="createPhoneNumber"
                      onChange={(e) => setPhoneNumberAccount(e.target.value)}
                      value={phoneNumberAccount}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm
                       text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-100 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="Phone Number"
                      required
                    />
                  </div>
                </div>
                <p className="text-base text-white dark:text-white"> -</p>
                <div className="h-px w-full dark:bg-navy-700">
                  {/* National ID/Passport */}
                  <div className="mb-6">
                    <label
                      htmlFor="createNationalID"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                    >
                      National ID/Passport*
                    </label>
                    <input
                      type="text"
                      id="createNationalID"
                      onChange={(e) => setNationalIdAccount(e.target.value)}
                      value={nationalIdAccount}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 
                      text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-100 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="national ID"
                      required
                    />
                  </div>
                </div>
              </div>

              <button
                className="linear 0 mt-4 w-full rounded-xl	bg-cyan-800 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-cyan-500 active:bg-cyan-800	 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={createAccountAuthentication}
              >
                Sign Up
              </button>
              <div className="mt-4">
                <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                  Not registered yet?
                </span>
                <button
                  onClick={() => {
                    setLogin(true);
                    setRegister(false);
                  }}
                  className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-cyan-800"
                >
                  Already have account!
                </button>
              </div>
            </div>
          )}
          {}
        </div>
        <LogoComponent />
      </div>
    );
  } else if (localLoan && createAccount && !forgetPassword) {
    return (
      <div className="flex flex-row">
        <div className="mb-20 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
          {applicationForm ? (
            <div className="xl:ml-[20vh] sm:ml-[1vh] md:mr-[20vh]  w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[900px] ">
              <h4 className="mt-8 text-4xl font-bold text-navy-700 dark:text-black">
                Application Form
              </h4>
              <div className="flex items-center">
                <p className="ml-2 mb-5 text-sm font-medium text-navy-700 dark:text-black">
                  Fill your information in the field below and click Apply
                </p>
              </div>
              <div className="mb-16 flex items-center  gap-3">
                <div className="h-px w-full dark:bg-navy-700">
                  {/* Registration Number */}
                  <div className="mb-6">
                    <input
                      type="text"
                      autoFocus
                      onChange={(e) => setRegistrationNumber(e.target.value)}
                      value={registrationNumber}
                      id="registrationNumber"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="Reg Number"
                      required
                    />
                  </div>
                </div>
                {/* First Name */}
                <div className="h-0.5 w-full  dark:bg-navy-700">
                  <div className="mb-6">
                    <input
                      type="text"
                      id="fName"
                      disabled={true}
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="First name"
                      required
                    />
                  </div>
                </div>
                <div className="h-0.5 w-full dark:bg-navy-700">
                  {/* Last Name */}

                  <div className="mb-6">
                    <input
                      type="text"
                      id="lName"
                      disabled={true}
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                </div>
                <div className="h-px w-full dark:bg-navy-700">
                  {/* Gender */}
                  <select
                    id="countries"
                    value={gender}
                    disabled={gender ? true : false}
                    onChange={(e) => setGender(e.target.value)}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-black focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option defaultValue>Select Gender</option>
                    <option value="MALE">MALE</option>
                    <option value="FEMALE">FEMALE</option>
                  </select>
                </div>
              </div>
              <div className="mb-16 flex items-center  gap-3 pt-2">
                {/* Phone Number */}
                <div className="h-px w-full  dark:bg-navy-700">
                  <div className="mb-6">
                    <input
                      type="text"
                      id="phoneNumber"
                      disabled={phoneNumber ? true : false}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="Phone Number"
                      required
                    />
                  </div>
                </div>
                {/* <p className="text-base text-gray-600 dark:text-white"> -</p> */}
                {/* National ID */}
                <div className="h-px w-full  dark:bg-navy-700">
                  <div className="mb-6">
                    <input
                      type="text"
                      id="nationalID"
                      disabled={nationalId ? true : false}
                      onChange={(e) => setNationalId(e.target.value)}
                      value={nationalId}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="national ID"
                      required
                    />
                  </div>
                </div>
                <div className="h-px w-full dark:bg-navy-700">
                  {/* Father Name */}
                  <div className="mb-6">
                    <input
                      type="text"
                      id="fatherName"
                      onChange={(e) => setFatherName(e.target.value)}
                      value={fatherName}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="Father Name"
                      required
                    />
                  </div>
                </div>

                <div className="h-px w-full dark:bg-navy-700">
                  {/* Mother Name */}

                  <div className="mb-6">
                    <input
                      type="text"
                      id="motherName"
                      onChange={(e) => setMotherName(e.target.value)}
                      value={motherName}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="Mother Name"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="mb-16 flex items-center gap-3 pt-2">
                <div className="h-px w-full dark:bg-navy-700">
                  {/* Guardian */}
                  <div className="mb-6">
                    <input
                      type="text"
                      id="guardian"
                      onChange={(e) => setGuardianName(e.target.value)}
                      value={guardianName}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="Guardian"
                      required
                    />
                  </div>
                </div>
                {/* Reference Number */}
                <div className="h-px w-full  dark:bg-navy-700">
                  <div className="mb-6">
                    <input
                      type="text"
                      id="indexNumber"
                      disabled={true}
                      onChange={(e) => setIndexNumber(e.target.value)}
                      value={indexNumber}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="Index Number"
                      required
                    />
                  </div>
                </div>
                <div className="h-0.5 w-full dark:bg-navy-700">
                  {/* Email */}
                  <div className="mb-6">
                    <input
                      type="text"
                      id="email"
                      disabled={email ? true : false}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="email"
                      required
                    />
                  </div>
                </div>
                <div className="h-px w-full dark:bg-navy-700">
                  {/* Marks */}
                  <div className="mb-6">
                    <input
                      type="text"
                      id="marks"
                      disabled={true}
                      onChange={(e) => setMarks(e.target.value)}
                      value={marks}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="marks"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="mb-16 flex items-center  gap-3 pt-2">
                <div className="h-px w-full dark:bg-navy-700">
                  <select
                    id="countries"
                    onChange={(e) => setCombination(e.target.value)}
                    value={combination}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option defaultValue>
                      {combination ? combination : "Select combination"}
                    </option>
                    {combinations &&
                      !combination &&
                      combinations.map((combination) => (
                        <option
                          key={combination.combinationCode}
                          value={combination.combinationCode}
                        >
                          {combination.combinationName}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="h-px w-full">
                  <select
                    id="countries"
                    onChange={(e) => {
                      setStudyArea(e.target.value);
                    }}
                    value={studyArea}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option defaultValue>
                      {studyArea ? studyArea : "Select Study area"}
                    </option>
                    {studyAreas &&
                      !studyArea &&
                      studyAreas.map((study) => (
                        <option key={study.id}>{study.studyAreaName}</option>
                      ))}
                  </select>
                </div>
                <div className="h-px w-full">
                  <select
                    id="countries"
                    onChange={(e) => setCompletionYear(e.target.value)}
                    value={completionYear}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option defaultValue>
                      {completionYear
                        ? completionYear
                        : "Select completion year"}
                    </option>
                    {academicYears &&
                      !completionYear &&
                      academicYears.map((academicYear) => (
                        <option
                          key={academicYear.id}
                          value={academicYear.academicYearCode}
                        >
                          {academicYear.academicYearName}
                        </option>
                      ))}
                  </select>
                </div>
                {/* Academic year Applying F-*/}
                <div className="h-px w-full ">
                  <select
                    id="countries"
                    onChange={(e) => setApplyingAcademic(e.target.value)}
                    value={applyingAcademic}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option defaultValue>
                      {applyingAcademic
                        ? applyingAcademic
                        : "Select Academic Year"}
                    </option>
                    {academicYears &&
                      !applyingAcademic &&
                      academicYears.map((academicYear) => (
                        <option
                          key={academicYear.id}
                          value={academicYear.academicYearCode}
                        >
                          {academicYear.academicYearName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="mb-16 flex items-center  gap-3 pt-3">
                <div className="h-px w-full ">
                  <select
                    id="countries"
                    onChange={(e) => {
                      setLoanCategory(e.target.value);
                    }}
                    value={loanCategory}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    {/* <option value="Living Allowance">Living Allowance</option>
                    <option value="Tuition Fees">Tuition Fees</option> */}
                    <option value="Tuition Fees and Living Allowance">
                      Tuition Fees and Living Allowance
                    </option>
                  </select>
                </div>
                <div className="h-px w-full dark:bg-navy-700">
                  {/* Guardian */}
                  <div className="mb-6">
                    <input
                      type="text"
                      id="collegeId"
                      disabled={true}
                      onChange={(e) => setCollege(e.target.value)}
                      value={college}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="College"
                      required
                    />
                  </div>
                </div>
                {/* Reference Number */}
                <div className="h-px w-full  dark:bg-navy-700">
                  <div className="mb-6">
                    <input
                      type="text"
                      id="qualificationId"
                      disabled={true}
                      onChange={(e) => setQualification(e.target.value)}
                      value={qualification}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="Qualification Code"
                      required
                    />
                  </div>
                </div>
                <div className="h-0.5 w-full dark:bg-navy-700">
                  {/* Email */}
                  <div className="mb-6">
                    <input
                      type="text"
                      id="campusId"
                      disabled={true}
                      onChange={(e) => setCampus(e.target.value)}
                      value={campus}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="Campus"
                      required
                    />
                  </div>
                </div>
                <div className="h-px w-full dark:bg-navy-700">
                  {/* Marks */}
                  <div className="mb-6">
                    <input
                      type="text"
                      id="educationLevelId"
                      disabled={true}
                      onChange={(e) => setEducationLevel(e.target.value)}
                      value={educationLevel}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="Education Level"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="mb-16 flex items-center  gap-3 pt-2">
                <div className="h-px w-full">
                  <select
                    id="district"
                    value={district}
                    onChange={(e) => {
                      fetchLocations(e.target.value, "DISTRICT");
                      setDistrict(e.target.value);
                    }}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option defaultValue>Select District</option>
                    {districts &&
                      districts.map((district) => (
                        <option
                          key={district.locationCode}
                          value={district.locationCode}
                        >
                          {district.locationName}
                        </option>
                      ))}
                  </select>
                </div>
                {/* sector */}
                <div className="h-px w-full">
                  <select
                    id="sector"
                    value={sector}
                    onChange={(e) => {
                      setsector(e.target.value);
                      fetchLocations(e.target.value, "SECTOR");
                    }}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option defaultValue>Select Sector</option>
                    {sectors &&
                      sectors.map((sector) => (
                        <option
                          key={sector.locationCode}
                          value={sector.locationCode}
                        >
                          {sector.locationName}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="h-px w-full">
                  <select
                    id="cell"
                    value={cell}
                    onChange={(e) => {
                      setCell(e.target.value);
                      fetchLocations(e.target.value, "CELL");
                    }}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option defaultValue>Select cell</option>
                    {cells &&
                      cells.map((cell) => (
                        <option
                          key={cell.locationCode}
                          value={cell.locationCode}
                        >
                          {cell.locationName}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="h-px w-full ">
                  <select
                    id="village"
                    onChange={(e) => {
                      setVillage(e.target.value);
                      setSelectedVillage(true);
                    }}
                    value={village}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option defaultValue>Select Village</option>
                    {villages &&
                      villages.map((village) => (
                        <option
                          key={village.locationCode}
                          value={village.locationCode}
                        >
                          {village.locationName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="mb-6 flex items-center  gap-3 pt-2">
                <div className="h-px w-full  dark:bg-navy-700">
                  <button
                    onClick={() => {
                      setLogin(true);
                      setLocalLoan(false);
                      clearTheInput();
                    }}
                    className="linear mt-2 w-full rounded-xl bg-cyan-800 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-400 active:bg-brand-700	 dark:bg-cyan-800	 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    Cancel
                  </button>
                </div>
                <div className="h-px w-full dark:bg-navy-700">
                  <button
                    onClick={applyForLocalLoan}
                    className="linear mt-2 w-full rounded-xl
                 bg-cyan-800 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700	 dark:bg-navy-700 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-[10vh] xl:ml-[20vh] sm:ml-[1vh] md:mr-[20vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
              <h4 className="text-4xl font-bold text-navy-700 dark:text-slate-800">
                View Your Result
              </h4>

              <div className="mb-16 flex items-center  gap-3">
                {/* First Name */}
                <div className="h-px w-full  dark:bg-navy-700">
                  <div className="mb-6">
                    <label
                      htmlFor="checkRegistration"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                    >
                      Registration Number*
                    </label>
                    <input
                      type="text"
                      id="checkRegistration"
                      onChange={(e) => setCheckResultRegNumber(e.target.value)}
                      value={checkResultRegNumber}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="Enter your Registration Number here"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Checkbox */}
              <div className="pt-10">
                <button
                  className="linear mt-2 w-full rounded-xl bg-cyan-800 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white 
                dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  onClick={() => {
                    getResultByRegNumber();
                  }}
                >
                  VIEW RESULTS
                </button>
              </div>
              <div className="mt-4">
                <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                  Back To login
                </span>
                <button
                  onClick={() => {
                    setLogin(true);
                    setLocalLoan(false);
                  }}
                  className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-cyan-800"
                >
                  Press here!
                </button>
              </div>
              <Modal show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>
                  <div className="flex">
                    <div className="text-2xl font-bold text-navy-700 dark:text-slate-800 flex-1">
                      STUDENT LOAN APPLICATION RESULTS
                    </div>
                    <div className="flex-none w-28">
                      {" "}
                      <img src={heclogo} alt="HEC" />
                    </div>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        First name : {firstName}
                      </label>
                    </div>
                    <div>
                      <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Last name : {lastName}
                      </label>
                    </div>
                    <div>
                      <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        National ID : {nationalId}
                      </label>
                    </div>
                    <div>
                      <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Registration Number : {registrationNumber}
                      </label>
                    </div>
                    <div>
                      <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Study Area : {studyArea}
                      </label>
                    </div>
                    <div>
                      <label class="block mb-2 text-xl font-bold text-teal-700 dark:text-white">
                        Decision :{" "}
                        {bursaryStatus === "PENDING"
                          ? "NOT GRANTED"
                          : bursaryStatus}
                      </label>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    color="gray"
                    onClick={() => {
                      setOpenModal(false);
                      clearResultFild();
                    }}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </div>
        <LogoComponent />
      </div>
    );
  } else if (localLoan && createAccount && forgetPassword) {
    return (
      <>
        {!emailSendSucceed ? (
          <div className="flex flex-row">
            <div className="mb-20 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
              <div className="mt-[10vh] xl:ml-[20vh] sm:ml-[1vh] md:mr-[20vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h4 className="text-4xl font-bold text-navy-700 dark:text-black">
                  Reset Your Password
                </h4>

                <div className="mb-16 flex items-center  gap-3">
                  {/* First Name */}
                  <div className="h-px w-full  dark:bg-navy-700">
                    <div className="mb-6">
                      <label
                        htmlFor="checkRegistration"
                        className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                      >
                        Please enter your email
                      </label>
                      <input
                        type="email"
                        id="checkRegistration"
                        value={emailForget}
                        onChange={(e) => setEmailForget(e.target.value)}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-100 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                        placeholder="email@example.com"
                        required={true}
                      />
                    </div>
                  </div>
                </div>
                {/* Checkbox */}
                <div className="pt-10">
                  <button
                    className="linear mt-2 w-full rounded-xl
                 bg-cyan-800 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700	 dark:bg-navy-700 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    onClick={sendEmail}
                  >
                    Search
                  </button>
                </div>
                <div className="mt-4">
                  <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                    Back To login
                  </span>
                  <button
                    onClick={() => {
                      setLogin(true);
                      setLocalLoan(false);
                    }}
                    className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-cyan-800"
                  >
                    Press here!
                  </button>
                </div>
              </div>
            </div>
            <div className="">
              <LogoComponent />
            </div>
          </div>
        ) : (
          <div className="flex flex-row">
            <div className="mb-20 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
              <div className="mt-[10vh] xl:ml-[20vh] sm:ml-[1vh] md:mr-[20vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h4 className="text-4xl font-bold text-navy-700 dark:text-black">
                  New Password Detail
                </h4>

                <div className="mb-16 flex-col">
                  {/* First Name */}

                  <div className="mb-6">
                    <label
                      htmlFor="checkRegistration"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                    >
                      Please enter your email
                    </label>
                    <input
                      type="email"
                      id="checkRegistration"
                      value={emailForget}
                      onChange={(e) => setEmailForget(e.target.value)}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-100 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="email@example.com"
                      required={true}
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="checkRegistration"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                    >
                      Please Enter your New Password
                    </label>
                    <input
                      type="password"
                      id="checkRegistration"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-100 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      required={true}
                    />
                  </div>
                  <div className="h-px w-full  dark:bg-navy-700">
                    <div className="mb-6">
                      <label
                        htmlFor="checkRegistration"
                        className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                      >
                        Your Token from Email
                      </label>
                      <input
                        type="text"
                        id="checkRegistration"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-100 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                        placeholder="xxx-xxx"
                        required={true}
                      />
                    </div>
                  </div>
                </div>
                {/* Checkbox */}
                <div className="pt-10">
                  <button
                    className="linear mt-2 w-full rounded-xl
                 bg-cyan-800 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700	 dark:bg-navy-700 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    onClick={resetNewPassword}
                  >
                    Reset
                  </button>
                </div>
                <div className="mt-4">
                  <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                    Back To login
                  </span>
                  <button
                    onClick={() => {
                      setLogin(true);
                      setLocalLoan(false);
                    }}
                    className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-cyan-800"
                  >
                    Press here!
                  </button>
                </div>
              </div>
            </div>
            <div className="">
              <LogoComponent />
            </div>
          </div>
        )}
      </>
    );
  }
}
