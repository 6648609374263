import { Sidebar } from "flowbite-react";
import { HiShoppingBag } from "react-icons/hi";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";

export default function SidebarComponent() {
  const token = JSON.parse(sessionStorage.getItem("user"));

  return (
    <Sidebar aria-label="Sidebar with multi-level dropdown example">
      <Sidebar.Items>
        <Sidebar.ItemGroup>
          <Sidebar.Item href="/dashboard" icon={MdOutlineDashboardCustomize}>
            Dashboard
          </Sidebar.Item>
          {token["role"]["roleName"] === "Admin" ||
          token["role"]["roleName"] === "DG" ? (
            <Sidebar.Collapse icon={HiShoppingBag} label="Loan Applications">
              {/* <Sidebar.Item href="/localloan">Local Loans</Sidebar.Item> */}
              <Sidebar.Item href="/loanApprovalStep">
                Loan Verification
              </Sidebar.Item>
              <Sidebar.Item href="/busaryapplication">
                Admission List
              </Sidebar.Item>
              <Sidebar.Item href="/selection">Selection Settings</Sidebar.Item>
              {/* <Sidebar.Item href="#">Refunds</Sidebar.Item>
            <Sidebar.Item href="#">Shipping</Sidebar.Item> */}
            </Sidebar.Collapse>
          ) : (
            <div></div>
          )}
          {/* <Sidebar.Item href="/LoanReport" icon={BiSolidReport}>
            Loan Report
          </Sidebar.Item> */}
          {/*
          <Sidebar.Item href="#" icon={HiUser}>
            Users
          </Sidebar.Item>
          <Sidebar.Item href="#" icon={HiShoppingBag}>
            Products
          </Sidebar.Item>
          <Sidebar.Item href="#" icon={HiArrowSmRight}>
            Sign In
          </Sidebar.Item>
          <Sidebar.Item href="#" icon={HiTable}>
            Sign Up
          </Sidebar.Item> */}
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
  );
}
